import React from "react";
import { inBrowser, parseUrl } from "@Utils/nextUtils";
import { AnyObject } from "@Types";
import { IClaims } from "@Lib/auth/session/session";

import useRequest, { makeResponse, MutateFn } from "@Hooks/useRequest";
import { IncomingMessage } from "http";
import { NextPageContext } from "next";
import request, { isAxiosError } from "@Lib/request";
import Debug from "@Utils/debug";

// import auth from '@Utils/auth';
import { AxiosError, AxiosResponse } from "axios";
import { inIframe, isAxiosRefreshTokenError } from "@Utils/helpers";
import { SWRConfig } from "swr";
import { STORAGE_KEY_MAP } from "@Utils/constants";
import useStorage from "@Hooks/useStorage";
export const STORAGE_KEY = STORAGE_KEY_MAP.user;
const debug = Debug("AuthProvider");

/**
 * setUser - Stores the user in session storage, provided that the function is called in a browser window
 * 
 * @param user - The user to be stored in session storage
 */
// @ts-ignore
const setUser = (user: IClaims) => {
  if (inBrowser()) sessionStorage.setItem(STORAGE_KEY, JSON.stringify(user));
  __AUTH.user = user;
};

/**
 * getUser - Gets the user from session storage, provided that the function is called in a browser window
 */
// @ts-ignore
const getUser = () => {
  if (!inBrowser()) return null;
  const user = sessionStorage.getItem(STORAGE_KEY);
  return user ? JSON.parse(user) : null;
};

/**
 * 
 */
const __AUTH: {
  baseUrl: string;
  basePath: string;
  user: IClaims | null;
} = {
  baseUrl: parseUrl(process.env.NEXT_HOST).base,
  basePath: parseUrl(process.env.NEXT_HOST).path,
  user: null,
};

/**
 * @constant FETCH_USER_REQUEST
 * 
 * Constant representing the request object that will be used to fetch the user
 */
// @ts-ignore
export const FETCH_USER_REQUEST = {
  url: __AUTH.basePath,
  params: {
    refresh: true,
  },
};


/**
 * @interface AuthProviderProps
 * 
 * Props to be supplied to the AuthProvider component
 */
export interface AuthProviderProps {
  value?: {
    user: IClaims | null;
    loading: boolean;
  };
  children: any;
}

export interface AuthProviderState {
  user: IClaims | null;
  error?: string;
  loading: boolean;
}

// Context exposed by the AuthProvider component
export interface IAuthContext {
  user: IClaims | null;
  loading: boolean;
  silent: boolean;
  error?: AxiosError<any>;
  mutate: MutateFn<{ user: IClaims | null }>;
}

export const AuthContext = React.createContext<IAuthContext>({
  user: null,
  loading: false,
  silent: false,
  mutate: async () => {
    throw new Error("Context must be instantiated.");
  },
});

const createUrl = (pathname: string, params?: AnyObject) => {
  const url = new URL(pathname, window.location.origin);
  if (params) {
    for (let [k, v] of Object.entries(params)) {
      url.searchParams.append(k, v);
    }
  }

  return url.toString();
};

// The signIn method can be called from anywhere in the application, and will redirect the user to the
// authentication provider. This must be called from within a browser window.
export const signIn = (args: AnyObject) => {
  if (inBrowser()) {
    const url = createUrl(`/api/login`, args);

    window.location.assign(url);
  }
};

// The signOut method can be called from anywhere in the application to clear the user session.
// Chatwoot will also be reset. After the user data is cleared, the user will be redirected to the
// logout url. This must be called from within a browser window.
export const signOut = (args: AnyObject) => {
  if (inBrowser()) {
    for (let entry of Object.entries(STORAGE_KEY_MAP)) {
      localStorage.removeItem(entry[1]);
      sessionStorage.removeItem(entry[1]);
    }
    // localStorage.clear();
    // sessionStorage.clear();
    if ((window as any).$chatwoot) (window as any).$chatwoot.reset();
    const url = createUrl("/api/logout", args);

    window.location.assign(url);
  }
};

export interface FetchUserOptions {
  req?: IncomingMessage;
  context?: NextPageContext;
}

// Fetches the user from the API. If a user already exists, it will return that user instead of fetching.
// This must be called from within a browser window.
export const fetchUser = async () => {
  const user = __AUTH.user;
  if (user) {
    return {
      data: { user },
      status: 200,
      statusText: "OK",
    } as AxiosResponse<{ user: IClaims }>;
  }
  const response = await request({
    url: __AUTH.basePath,
  });

  return response;
};

// Ignoring this for now since it could be reimplimented
// @ts-ignore
const SilentRefreshIframe = ({
  onMessage,
}: {
  onMessage: (e: MessageEvent) => void;
}) => {
  const ref = React.useRef<HTMLIFrameElement>(null);
  React.useEffect(() => {
    const { current: frame } = ref;
    const messageChannel = new MessageChannel();
    if (frame) {
      frame.addEventListener("load", ev => {
        debug.log("iframe has loaded", ev);
        if (frame.contentWindow) {
          const message = {
            source: "check-session",
          };
          debug.log("sending message %j to frame", message);
          try {
            frame.contentWindow.postMessage(message, "*", [
              messageChannel!.port2,
            ]);
          } catch (err) {
            debug.error(err);
          }
        }
      });
      debug.log("Listening on message channel port 1", messageChannel!.port1);
      messageChannel.port1.onmessage = onMessage;
    }
    return () => {
      messageChannel.port1.onmessage = null;
    };
  }, [onMessage]);

  return (
    <iframe
      title="SilentRefresh"
      src="/api/login?prompt=none&returnTo=/silent-callback"
      style={{ position: "absolute", height: 0, width: 0, border: 0 }}
      ref={ref}
    />
  );
};

// let silentRenewalRequest = new Set<string>();
const AuthProvider = ({ children }: AuthProviderProps) => {
  // Should we render silent renewal iframe / are we in silent renewal process?
  const [silent] = React.useState(false);

  const storage = useStorage("user");

  __AUTH.user = storage.get();

  // User data
  const {
    user,
    loading,
    mutate: mutateUser,
    error,
  } = useFetchUser(__AUTH.user);

  const handleSilentRenewError = React.useCallback((error: AxiosError<any> | undefined) => {
    if (isAxiosError(error)) {
      if (isAxiosRefreshTokenError(error) && user) {
        __AUTH.user = null;
        storage.clear();
        mutateUser(makeResponse({user: null}), { revalidate: false });
      }
    }
  },[user, mutateUser, storage]);

  React.useEffect(() => {
    if (error) handleSilentRenewError(error);
  }, [error, handleSilentRenewError]);


  // Always runs on render, but only once. Sets up message event listeners for starting the silent renew process
  // const finishSilentRenewalCallback = () => {
  //   setSilent(false);
  //   window.postMessage(
  //     {
  //       source: "finish_silent_refresh",
  //     },
  //     "*",
  //   );

  //   return;
  // };
  // //@ts-ignore
  // const messageChannelHandler = (e: MessageEvent) => {
  //   debug.log("Incoming message on port 1: %o", e);
  //   if (e.data && e.data.sender === "check-session") {
  //     debug.log("FinishSilentRefreshListener callback");
  //     finishSilentRenewalCallback();
  //   }
  // };
  // React.useEffect(() => {
  //   const startSilentRenewalCallback = (event: MessageEvent) => {
  //     if (
  //       event.data &&
  //       event.data.source &&
  //       !(event.data.source as string).startsWith("react")
  //     ) {
  //       if (
  //         event.data &&
  //         (event.data.source === "start_silent_renew" ||
  //           event.data.source === "in_silent_renew")
  //       ) {
  //         if (event.data.source === "start_silent_renew") setSilent(true);
  //         if (event.data.payload) {
  //           silentRenewalRequest.add(JSON.stringify(event.data.payload));
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener("message", startSilentRenewalCallback);

  //   return () => {
  //     debug.log("Stop listening on message channel port 1");
  //     window.removeEventListener("message", startSilentRenewalCallback);
  //   };
  // }, []);

  // React.useEffect(() => {
  //   debug.debug("Silent renewal state: %s", silent);
  //   (window as any).SILENT_RENEWAL_ACTIVE = silent;
  //   if (!silent && silentRenewalRequest.size > 0) {
  //     (async () => {
  //       debug.debug("Updating data for stalled requests: %O", [
  //         ...silentRenewalRequest,
  //       ]);

  //       await mutateUser(async (): Promise<AxiosResponse<{ user: IClaims; }> | undefined> => {
  //         if (inIframe()) return;
  //         try {
  //           const response = await request({
  //             url: __AUTH.basePath,
  //           });
  //           return response;
  //         } catch (err) {
  //           if (isAxiosError(err)) {
  //             debug.log("Error silently renewing user access: %O", err);
  //             throw err;
  //           }
  //         }
  //       }, false);
  //       for (let req of silentRenewalRequest) {
  //         const reqObj = JSON.parse(req);
  //         if (reqObj.url !== "/api/me") await mutate(req);
  //         silentRenewalRequest.delete(req);
  //       }
  //     })();
  //   }
  // }, [silent, mutateUser]);

  // When there is an error relating to the fetched user, set globals / storage to null
  React.useEffect(() => {
    if (error && error.response && error.response.data) {
      // MAYBE NOT NEED?
      if (
        isAxiosRefreshTokenError(error)
      ) {
        __AUTH.user = null;
        storage.clear();
        // mutateUser(makeResponse({user: null}));
      }
    }
  }, [error, storage, mutateUser]);

  React.useEffect(() => {
    debug.debug("User updated: %o", user);
    if (user) {
      storage.set(user);
    }

    var storeUser = storage.get();

    if (!user && storeUser) {
      storage.clear();
    }

    __AUTH.user = user;

  }, [user, storage]);

  return (
    <AuthContext.Provider
      value={{
        user: user && (!error || (error && silent)) ? user : null,
        error,
        silent,
        loading,
        mutate: mutateUser,
      }}
    >
      <SWRConfig value={{
        onError: (err, _) => {
          handleSilentRenewError(err);
        }
      }}>
        {children}
        {/* {silent && <SilentRefreshIframe onMessage={messageChannelHandler} />} */}
      </SWRConfig>
    </AuthContext.Provider>
  );
};

export const useUser = () => React.useContext(AuthContext);
//@ts-ignore
export const useFetchUser = (user?: IClaims | null) => {
  const { data, error, mutate, isValidating } = useRequest<
    { user: IClaims | null },
    AxiosError
  >(
    () => {
      return !inIframe()
        ? {
            url: __AUTH.basePath,
            params: {
              refresh: true,
            },
          }
        : null;
    },
    {
      // fallbackData: user ? { user } : undefined,
      focusThrottleInterval: 10 * 60 * 1000,
      // if we fail fetching the user give up - this is just parsing the cookie
      shouldRetryOnError: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
    },
  );

  return {
    user: data && data.user ? data.user : null,
    error,
    loading: !data && isValidating,
    mutate,
  };
};

export default AuthProvider;
