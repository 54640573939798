import React, { useRef } from "react";
import { inBrowser } from "@Utils/nextUtils";
import { AnyObject } from "@Types";
import { normalizePhone } from "@Utils/formUtils";
import { AxiosResponse } from "axios";
import request from "./request";
import useRequest from "@Hooks/useRequest";
import { useClient } from "src/context/ClientProvider";
import { useUser } from "src/context/AuthProvider";
import Debug from "@Utils/debug";

const debug = Debug("chatwoot");
export interface ChatwootConfig {
  baseUrl: string;
  websiteToken: string;
}

export interface ChatwootProps extends ChatwootConfig {
  settings: ChatwootSettings;
}

interface ChatwootState {
  isOpen: boolean;
  hasLoaded: boolean;
  user?: AnyObject;
}

interface ChatwootSDK {
  deleteCustomAttribute: (key: string) => void;
  removeLabel: (label: string) => void;
  reset: () => void;
  setCustomAttributes: (attributes: AnyObject) => void;
  setLabel: (label: string) => void;
  setLocale: (locale: ChatwootLocale) => void;
  setUser: (userid: string, user: AnyObject) => void;
  toggle: () => void;
}

type UseChatwootValue = ChatwootConfig &
  ChatwootSettings &
  ChatwootSDK &
  ChatwootState;

type ChatwootLocale =
  | "ar"
  | "ca"
  | "cs"
  | "da"
  | "de"
  | "el"
  | "en"
  | "es"
  | "fa"
  | "fi"
  | "fr"
  | "hi"
  | "hu"
  | "it"
  | "ja"
  | "ko"
  | "ml"
  | "nl"
  | "pl"
  | "pt"
  | "pt_BR"
  | "ro"
  | "ru"
  | "sk"
  | "sv"
  | "ta"
  | "tr"
  | "uk"
  | "vi"
  | "zh";

export interface ChatwootSettings {
  type?: "standard" | "expanded_bubble";
  hideMessageBubble?: boolean;
  position?: "right" | "left";
  showPopoutButton?: boolean;
  launcherTitle?: string;
  locale?: ChatwootLocale;
}

interface UseChatwootInternalState {
  configured: boolean;
  ready: boolean;
}

export type ChatwootProviderProps = {
  children: any;
  value: {
    config?: ChatwootConfig;
    settings?: ChatwootSettings;
  };
};

/**
 * Global to maintain config values between page navigations
 */
const __CHATWOOT: { config?: ChatwootConfig } = {
  config:
    process.env.NEXT_PUBLIC_CHATWOOT_BASE_URL &&
    process.env.NEXT_PUBLIC_CHATWOOT_TOKEN
      ? {
          baseUrl: process.env.NEXT_PUBLIC_CHATWOOT_BASE_URL,
          websiteToken: process.env.NEXT_PUBLIC_CHATWOOT_TOKEN,
        }
      : undefined,
};

/**
 * Returns the latest chatwoot sdk object from the window, otherwise returns undefined
 */
const getChatwootSdk = (): UseChatwootValue | undefined =>
  inBrowser() ? (window as any).$chatwoot : undefined;

/**
 * Initial state producer for UseChatwoot hook state
 *
 * @returns {UseChatwootInternalState}
 */
const useChatwootInitialState = (): UseChatwootInternalState => {
  const chatwoot = useChatwootSdk();

  return {
    configured: !!chatwoot,
    ready: chatwoot ? chatwoot.hasLoaded : false,
  };
};

const ChatwootContext = React.createContext<UseChatwootValue | undefined>(
  getChatwootSdk(),
);

/**
 * Chatwoot config fetcher; returns a dummy response if values are in global
 */
const fetchChatwootConfig = async () => {
  if (__CHATWOOT.config)
    return {
      data: __CHATWOOT.config,
      status: 200,
      statusText: "OK",
    } as AxiosResponse<ChatwootConfig>;

  const response = await request.get("/api/chat");

  if (response.data) __CHATWOOT.config = response.data;

  return response;
};

export const ChatwootProvider = ({
  value,
  children,
}: ChatwootProviderProps) => {
  const { config, settings } = value;
  const [state, setState] = React.useState(useChatwootInitialState);
  const { user } = useUser();
  const { client } = useClient();
  const chatwootRef = useRef(getChatwootSdk());

  // Initialize sdk
  React.useEffect(() => {
    if (!__CHATWOOT.config && config) {
      __CHATWOOT.config = config;
    }
    const win = window as any;
    const setReady = () => {
      chatwootRef.current = win.$chatwoot;
      setState(s => ({ ...s, ready: true }));
    };
    win.addEventListener("chatwoot:ready", setReady);

    if (!state.configured && config) {
      const { baseUrl, websiteToken } = config;
      if (baseUrl && websiteToken) {
        const script = document.createElement("script");
        script.src = baseUrl + "/packs/js/sdk.js";
        script.async = true;
        script.onload = function () {
          if (settings) win.chatwootSettings = settings;
          win.chatwootSDK.run({
            websiteToken,
            baseUrl,
          });
        };

        document.body.appendChild(script);

        setState(s => ({
          ...s,
          configured: true,
        }));

        debug.debug("Chatwoot configured");

        return () => {
          win.removeEventListener("chatwoot:ready", setReady);
          document.body.removeChild(script);
        };
      }
    }
  }, [config, settings, state.configured]);

  React.useEffect(() => {
    if (user && state.ready) {
      // only attempt to set user if user isn't set
      if (!chatwootRef.current!.user) {
        debug.debug("updating chatwoot user", user);
        chatwootRef.current!.setUser(user.sub, {
          email: user.email,
          name: `${user.given_name} ${user.family_name}`,
        });
      }
    }
  }, [user, state.ready]);

  // set attributes from client
  React.useEffect(() => {
    if (client && state.ready) {
      debug.debug("updating chatwoot attributes", client);
      chatwootRef.current!.setCustomAttributes({
        Title: client.Title,
        "Client Id": client.Id,
        Phone: normalizePhone(client.Phone),
        "Office Address": `${client.Office.Street}, ${client.Office.City}, ${client.Office.State}, ${client.Office.Zip}`,
        "Company Name": client.Office.CompanyName,
      });
    }
  }, [client, state.ready]);

  return (
    <ChatwootContext.Provider value={chatwootRef.current}>
      {children}
    </ChatwootContext.Provider>
  );
};

/**
 * Returns config for chatwoot, fetching it if it doesn't already exist
 */
export const useFetchChatwootConfig = () => {
  const { data } = useRequest(
    {
      url: "/api/chat",
    },
    {
      fallbackData: __CHATWOOT.config ? __CHATWOOT.config : undefined,
      fetcher: fetchChatwootConfig,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
    },
  );

  return data;
};

/**
 * Hook version of getChatwootSdk
 *
 * @see getChatwootSdk
 */
export const useChatwootSdk = (): UseChatwootValue | undefined => {
  return getChatwootSdk();
};

/**
 * Grabs sdk from context
 */
export const useChatwoot = () => React.useContext(ChatwootContext);
