export interface Query<T> {
  where?: WhereQueryParameters<T>;
  orderBy?: OrderQueryParameters<T>;
  groupBy?: GroupQueryParameters<T>;
  select?: SelectQueryParameters<T>;
  paging?: PagingQueryParameters;
  // for addl query params
  query?: Record<string, string>;
}
export type Unpacked<T> = T extends (infer U)[]
  ? U
  : T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T;

export type OrderQueryParameters<T> = Array<keyof Unpacked<T>>;

export type GroupQueryParameters<T> = keyof T;

export type WhereClauseString = keyof typeof WhereClause;

export enum WhereClause {
  eq = "==",
  neq = "!=",
  gt = ">",
  gte = ">=",
  lt = "<",
  lte = "<=",
}

export type WhereQueryObject<T> = {
  [P in keyof T]?: T[P] | null;
};

export type WhereQueryParameters<T> = {
  [P in WhereClauseString]?: WhereQueryObject<T>;
};

export type SelectQueryParameters<T> = Array<keyof T>;

export type PagingQueryParameters = {
  skip?: number;
  page?: number;
  take: number;
};
