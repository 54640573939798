import debug, { Debugger } from "debug";
import { Subtract } from "utility-types";
import { inBrowser } from "./nextUtils";
import React from "react";
export interface Logger {
  log: Debugger;
  error: Debugger;
  debug: Debugger;
  warn: Debugger;
  disable: (pattern?: string | LogLevel | string[] | LogLevel[]) => void;
  enable: () => void;
}
export type LogLevel = "info" | "error" | "debug";
function getLogFunctions(
  name: string,
  baseLog: Debugger,
  logLevel?: LogLevel,
): Logger {
  const extendedLog = baseLog.extend(name);
  const error = extendedLog.extend("error");
  error.log = (...args: any[]) => console.error.apply(console, args);

  const log = extendedLog.extend("info");
  log.log = (...args: any[]) => console.log.apply(console, args);

  const info = extendedLog.extend("debug");
  info.log = (...args: any[]) => console.debug.apply(console, args);

  const warn = extendedLog.extend("warn");
  warn.log = (...args: any[]) => console.warn.apply(console, args);

  const disable = (pattern?: string | LogLevel | string[] | LogLevel[]) => {
    if (Array.isArray(pattern)) {
      for (let p of pattern) {
        disable(p);
      }
    }
    const isLogLevel = ((p): p is LogLevel => {
      return Boolean(p && (p === "info" || p === "error" || p === "debug"));
    })(pattern);

    if (isLogLevel) {
      pattern = `${name}:${pattern}`;
    }
    const disablePattern = pattern
      ? `${baseLog.namespace}:${pattern}`
      : `${baseLog.namespace}:${name}:*`;
    const namespaces = debug.disable();

    const newNamespaces = [...new Set(namespaces.split(","))]
      .filter(v => v !== `-${disablePattern}`)
      .join(",");
    debug.enable(`${newNamespaces},-${disablePattern}`);
  };

  const enable = () => {
    const enablePattern = `${baseLog.namespace}:${name}:*`;
    const namespaces = debug.disable();

    const newNamespaces = [...new Set(namespaces.split(","))]
      .filter(v => v !== enablePattern)
      .join(",");
    debug.enable(newNamespaces);
  };

  if (logLevel === "info") {
    disable("debug");
  }

  if (logLevel === "error") {
    disable(["debug", "info", "warn"]);
  }

  return {
    error,
    warn,
    log,
    debug: info,
    disable,
    enable,
  };
}

export function createLogger(
  namespace: string = inBrowser() ? "app" : "server",
  // level: LogLevel = process.env.NODE_ENV === "development" ? "debug" : "error",
  // disableOthers: boolean = true,
) {
  let baseLog: Debugger = debug(namespace);

  return (name: string = "default"): Logger => getLogFunctions(name, baseLog);
}

export type WithLoggerProps<P = {}> = P & InjectedLoggerProps;

export type InjectedLoggerProps = {
  logger: Logger;
};

export const withLogger =
  <P extends InjectedLoggerProps>(Component: React.ComponentType<P>) =>
  (name: string) => {
    const logger = createLogger()(name);

    return class WithLogger extends React.Component<
      Subtract<P, InjectedLoggerProps>
    > {
      render() {
        return <Component {...(this.props as P)} logger={logger} />;
      }
    };
  };

export default createLogger();
