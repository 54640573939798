import { inBrowser } from "@Utils/nextUtils";
import client from "./request.client";
import server from "./request.server";
import { AxiosRequestConfig, AxiosError } from "axios";
import { Logger } from "winston";

export function getRequest() {
  if (inBrowser()) return client;
  return server;
}

type CondensedAxiosRequestConfig = Omit<
  AxiosRequestConfig,
  | "transformRequest"
  | "transformResponse"
  | "paramsSerializer"
  | "onUploadProgress"
  | "onDownloadProgress"
  | "validateStatus"
  | "httpsAgent"
  | "httpAgent"
>;

export interface NormalizedAxiosError extends Omit<AxiosError, "config"> {
  config: CondensedAxiosRequestConfig;
}

export function logRequestError(logger: Logger, err: any, message?: string) {
  const errObj: any = {
    message: err.message,
    name: err.name || "Request Error",
    stack: err.stack,
    response: {
      status: err.response?.status || err.status,
      statusText: err.response?.statusText,
      data: JSON.stringify(err.response?.data),
      headers: err.response?.headers,
    },
    code: err.code,
    // request: err.request,
  };

  if (err.config) {
    errObj.config = {
      url: err.config.url,
      method: err.config.method,
      baseUrl: err.config.baseURL,
      headers: err.config.headers,
      params: err.config.params,
      data: err.config.data,
      timeout: err.config.timeout,
      timeoutErrorMessage: err.config.timeoutErrorMessage,
      xsrfCookieName: err.config.xsrfCookieName,
      xsrfHeaderName: err.config.xsrfHeaderName,
      responseType: err.config.responseType,
      withCredentials: err.config.withCredentials,
    };
  }

  if (message) {
    logger.error(message, errObj);
  } else logger.error(errObj);
}

export function processRequestError(
  err: any,
): NormalizedAxiosError | undefined {
  const axiosError = err as AxiosError;
  if (axiosError.isAxiosError) {
    const { config } = axiosError;
    // delete unneeded properties
    delete config.onDownloadProgress;
    delete config.onUploadProgress;
    delete config.paramsSerializer;
    delete config.transformRequest;
    delete config.transformResponse;
    delete config.validateStatus;
    delete config.httpAgent;
    delete config.httpsAgent;
    return {
      ...axiosError,
      config,
    };
  }
}

export const isAxiosError = (error: unknown): error is AxiosError => {
  return 'isAxiosError' in (error as Error);
}

export default getRequest();
