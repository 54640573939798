import { INVALID_REFRESH_TOKEN } from "@Lib/auth/constants";
import RefreshTokenError from "@Lib/auth/tokens/refresh-token-error";
import { AxiosError } from "axios";
import { inBrowser } from "./nextUtils";
import Debug from "@Utils/debug";
import { isAxiosError } from "@Lib/request";

const debug = Debug("utils:helpers");

export const hasValue = (input: any) =>
  typeof input !== "undefined" && input !== null;

export const isIE11OrLower = () => {
  return (
    typeof window !== "undefined" &&
    typeof (document as any).documentMode !== "undefined"
  );
};

export function inIframe() {
  if (!inBrowser()) return false;
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isRefreshTokenError(err: RefreshTokenError): boolean {
  return !!err.code && err.code === INVALID_REFRESH_TOKEN;
}

export function isAxiosRefreshTokenError(err: unknown): err is AxiosError {
  return (
    isAxiosError(err) && err.response && err.response.data && isRefreshTokenError(err.response.data)
  );
}

export function finishSilentRefreshCallback(
  cb: (e: MessageEvent) => void | Promise<void>,
) {
  return (e: MessageEvent) => {
    if (e.data && e.data.source === "finish_silent_refresh") {
      debug.log("FinishSilentRefreshListener callback");
      cb(e);
    }
  };
}

export function createFinishSilentRefreshListener(
  cb: (e: MessageEvent) => void | Promise<void>,
  remove: boolean = true,
) {
  if (!inBrowser) return;

  let callback = finishSilentRefreshCallback(e => {
    cb(e);
    if (remove) window.removeEventListener("message", callback);
  });

  window.addEventListener("message", callback);
}
