import { useCallback } from "react";
import Debug, { Logger } from "@Utils/debug";
import { STORAGE_KEY_PREFIX } from "@Utils/constants";
import { inBrowser } from "@Utils/nextUtils";

// @ts-ignore
const debug = Debug("hooks:useStorage");

interface StorageProviderMethods {
  extend(key: string): StorageProvider;
  get(key: string): any;
  set(key: string, value: any): any;
  clear(key?: string): string[];
}

interface StorageProvider extends StorageProviderMethods {
  root: boolean;
  key: string;
  parent: StorageProvider | null;
  children: StorageProvider[];
}

abstract class AbstractStorageProvider implements StorageProviderMethods {
  keys: string[] = [];
  key: string = "";

  protected abstract log: Logger | null;

  constructor(key?: string) {
    this.key = key ?? this.key;
  }

  abstract extend(key: string): StorageProvider;
  abstract get(key: string): any | null;
  abstract set(key: string, value: any): void;
  abstract clear(key?: string): string[];

  protected hasKey(key: string) {
    return this.keys.indexOf(key) >= 0;
  }

  protected buildKey(...keys: any[]) {
    return [this.key, ...keys.filter(v => typeof v === "string")].join(":");
  }
}

class NullStorageProvider
  extends AbstractStorageProvider
  implements StorageProvider
{
  log: null = null;
  root: boolean = true;
  key: string = "";
  parent: StorageProvider | null = null;
  children: StorageProvider[] = [];
  extend(_: string): StorageProvider {
    return this;
  }
  get(_: string): any {
    return null;
  }
  set(_: string, __: any) {
    return;
  }
  clear(_?: string): string[] {
    return [];
  }
}

class RootStorageProvider
  extends AbstractStorageProvider
  implements StorageProvider
{
  root: boolean = true;
  key: string = STORAGE_KEY_PREFIX;
  parent: StorageProvider | null = null;
  children: StorageProvider[] = [];
  keys: string[] = [];
  log: Logger = Debug("lib:storage:RootStorageProvider");

  extend(key: string): StorageProvider {
    return new StorageProviderInstance(key, this);
  }

  get(key: string) {
    let result: string | null = null;
    let fullkey = this.buildKey(key);

    result = sessionStorage.getItem(fullkey);

    if (result) {
      try {
        result = JSON.parse(result);
      } catch {
        // ignore error
      }
    }

    return result;
  }

  set(key: string, value: any) {
    let fullkey = this.buildKey(key);
    let stringify = typeof value !== "string";
    if (!this.hasKey(key)) this.keys.push(key);
    sessionStorage.setItem(fullkey, stringify ? JSON.stringify(value) : value);
  }

  clear(key?: string): string[] {
    let fullkey = this.buildKey(key);
    let cleared: string[] = [];
    if (key) {
      if (!this.hasKey(key)) {
        this.log.warn("The key %s was not found in keys array", key);
      }
      sessionStorage.removeItem(fullkey);
      cleared.push(key);
    } else {
      for (let iKey in this.keys) {
        sessionStorage.removeItem(this.buildKey(iKey));
        cleared.push(iKey);
      }
    }

    this.keys = this.keys.filter(v => cleared.indexOf(v) === -1);
    return cleared;
  }
}

class StorageProviderInstance
  extends AbstractStorageProvider
  implements StorageProvider
{
  public root: boolean = false;
  public parent!: StorageProvider;
  public children: StorageProvider[] = [];
  log: Logger;

  constructor(key: string, parent: StorageProvider) {
    super(key);

    this.parent = parent;
    this.log = Debug(`lib:storage:StorageProvider:${key}`);
  }

  extend(key: string): StorageProvider {
    return new StorageProviderInstance(this.buildKey(key), this);
  }

  get(key: string) {
    return this.parent.get(this.buildKey(key));
  }

  set(key: string, value: any) {
    this.parent.set(this.buildKey(key), value);
  }

  clear(key?: string): string[] {
    return this.parent.clear(key ? this.buildKey(key) : undefined);
  }

  clearAll(): string[] {
    throw new Error("Method not implemented.");
  }
}

let STORAGE_PROVIDER: RootStorageProvider | NullStorageProvider;

const storageProviderFactory = () => {
  if (!STORAGE_PROVIDER) {
    STORAGE_PROVIDER = inBrowser()
      ? new RootStorageProvider()
      : new NullStorageProvider();
  }

  return STORAGE_PROVIDER;
};

export interface UseStorageResult<T> {
  get: (getKey?: string, fn?: (record: T) => boolean) => T | null;
  set: (record: T, setKey?: string) => void;
  clear: (clearKey?: string) => void;
  clearAll: () => void;
}

let __KEYS_CACHE: string[] = [];

export const getKeys = () => __KEYS_CACHE;

const useStorage = <T = any>(keyPart?: string): UseStorageResult<T> => {
  let provider = storageProviderFactory();

  const get = useCallback(
    (getKey?: string): T | null => {
      if (!keyPart) {
        if (!getKey) return null;
        return provider.get(getKey);
      }
      return provider.get(keyPart);
    },
    [keyPart, provider],
  );

  const set = useCallback(
    (record: T, setKey: string | undefined = keyPart) => {
      if (!setKey) {
        return;
      }
      return provider.set(setKey, record);
    },
    [keyPart, provider],
  );

  const clear = useCallback(
    (clearKey?: string) => {
      return provider.clear(clearKey ?? keyPart);
    },
    [keyPart, provider],
  );

  return {
    get,
    set,
    clear,
    clearAll: () => clear(),
  };
};

export default useStorage;
