import { createContext } from "react";
import {
  Product,
  SubjectInfo,
  ClaimInfo,
  Target,
} from "../interfaces";

export interface CartOrderState {
  ClientId: number | null;
  Subject: SubjectInfo | null;
  Claim: ClaimInfo | null;
  Targets: Target[];
  FacilityTypes: number[];
}

export interface ICartContext {
  items: Product[];
  
  
  clear(storageOnly?: boolean): void;
  add(item: Product): void;
  remove(item: Product): void;
}



export default createContext<ICartContext>({
  items: [],
  clear: () => {
    throw new Error("Cart not initialized");
  },
  add: () => {
    throw new Error("Cart not initialized");
  },
  remove: () => {
    throw new Error("Cart not initialized");
  },
});
