import React, { useState, useEffect, useCallback } from "react";
import CartContext from "./CartContext";
import { Product } from "../interfaces";
// import useClient from "../hooks/useClient";
import useStorage from "@Hooks/useStorage";

export interface CartProviderOptions {
  initialState?: () => Product[];
}

const CartProvider = ({
  initialState,
  children,
}: React.PropsWithChildren<CartProviderOptions>) => {
  const [items, setItems] = useState<Product[]>(initialState || []);

  const {
    set: setStorage,
    get: getStorage,
    clear: clearStorage,
  } = useStorage<Product[]>("cart");

  const add = useCallback(
    (n: Product) => {
      const newItems = [...items, n];
      setItems(newItems);
      setStorage(newItems);
    },
    [items, setItems, setStorage],
  );
  const remove = useCallback(
    (n: Product) => {
      const newItems = items.filter(i => i.Id !== n.Id);
      setItems(newItems);
      setStorage(newItems);
    },
    [items, setItems, setStorage],
  );
  const clear = useCallback(
    (_storageOnly?: boolean) => {
      setItems([]);
      clearStorage();
    },
    [setItems, clearStorage],
  );

  useEffect(() => {
    let active = true;

    const storedItems = getStorage();

    if (storedItems && active) {
      setItems(storedItems);
    }

    return () => {
      active = false;
    };
  }, [setItems, getStorage]);

  return (
    <CartContext.Provider
      value={{
        items,
        add,
        remove,
        clear,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
