export interface Address {
  Street: string;
  City: string;
  State: string;
  Zip: string;
}

export interface Subject {
  ClientId?: number;
  First: string;
  Middle?: string;
  Last?: string;
  Suffix?: string;
  DateOfBirth?: string;
  SocialSecurityNumber?: string;
  Injury: string;
  Street?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Spouse?: string;
  HomePhone?: string;
  WorkPhone?: string;
  MobilePhone?: string;
  Occupation?: string;
  CurrentEmployer?: string;
  FormerEmployers?: string;
  MotorVehicle?: string;
  MaritalStatusId?: number;
}

export type SubjectType = "company" | "person";

export interface SubjectInput {
  SubjectType: SubjectType;
  CompanyName?: string;
  First: string;
  Middle?: string;
  Last: string;
  Suffix?: string;
  DateOfBirth?: Date | null;
  SocialSecurityNumber?: string;
  Injury: string;
  HomePhone?: string;
  WorkPhone?: string;
  MobilePhone?: string;
  Websites?: string[];
  Occupation?: string;
  CurrentEmployer?: string;
  FormerEmployers?: string;
  MotorVehicle?: string;
  MaritalStatus?: {
    label: string;
    value: number;
  };
  Spouse?: string;
  Address: Partial<Address>;
}

export interface ClaimType {
  Id: number;
  Name: string;
  ShortName: string;
  SortOrder?: number;
}

export interface Registration {
  CompanyId?: number;
  OfficeId?: number;
  TitleId?: number;

  First: string;
  Last: string;
  Phone: string;
  Fax?: string;
  Email: string;
}

export interface Client {
  Id: string;
  Office: Office;
  CompanyId: string;
  DomainId: string;
  Title: string;
  First: string;
  Last: string;
  Phone: string;
  Extension: string;
  Email: string;
  Fax: string;
  SpecialInstructions: string;
  InvoiceEmail: string;
  ReportEmail: string;
  InvoiceEmailCopyTo: string;
  ReportEmailCopyTo: string;
}

export interface Title {
  Id: number;
  Name: string;
}

export interface Office {
  Id?: number;
  DomainId?: number;
  CompanyId?: number;
  Phone: string;
  Fax?: string;
  CompanyName: string;
  Address?: string;
  Street: string;
  City: string;
  State: string;
  Zip: string;
  Created?: boolean;
}

export interface Company {
  Id: number;
  Name: string;
}

export interface Product {
  Id: string;
  Active: boolean;
  Name: string;
  Description: string;
  Price?: number;
  IsMedical: boolean;
  IsExtended: boolean;
  // ProductTypeId?: number;
  ProductTypeName: string;
  SortOrder: number;
}

export type ShowcaseProduct = Omit<
  Product,
  "IsExtended" | "ProductTypeName" | "SortOrder" | "Active"
>;

export interface Domain {
  Id: number;
  Name: string;
  StatusDisplay: string;
}

export interface Discount {
  Description: string;
  Amount: number;
}

export interface CartItem {
  ProductType: string;
  ProductTypeId: number;
  ProductId: number;
  ProductName: string;
  Quantity: number;
  UnitPrice: number;
  SubTotal: number;
}

export interface CartResponse {
  Items: CartItem[];
  SubTotal: number;
  Tax: number;
  Discounts: Discount[];
  Total: number;
}

export interface CartRequest {
  ClientId?: number;
  ProductIds: number[];
  FacilityCount?: number;
}

export interface MedicalServiceCodeModel {
  Id: number;
  Code: string;
  Name: string;
}

export interface OrderRequest {
  ClientId: number;
  Cart: CartRequest;
  Subject: Subject;
  Claim: ClaimInfo;
  Targets: Address[];
  FacilityTypes: number[];
}

export interface OrderInfo {
  ClientId: number;
  Cart: CartResponse;
  Subject: Subject;
  Claim: ClaimInfo;
  Targets: Address[];
  FacilityTypes: number[];
}

export interface Target extends Address {
  isHomeAddress: boolean;
}

export interface SubjectInfoBasic {
  ClientId?: number;
  First: string;
  Middle?: string;
  Last: string;
  Suffix?: string;
  DateOfBirth?: string;
  SocialSecurityNumber: string;
  Injury: string;
  Street: string;
  City: string;
  State: string;
  Zip: string;
}

export interface SubjectInfoExtended {
  Spouse: string;

  HomePhone?: string;
  WorkPhone?: string;
  MobilePhone?: string;
  Email?: string;
  Occupation?: string;
  CurrentEmployer?: string;
  FormerEmployers?: string;
  MotorVehicle?: string;
  MaritalStatus?: string;
  PreviousAddresses?: string;
}

export type SubjectInfo = SubjectInfoBasic & SubjectInfoExtended;
export type ClaimOption =
  | "IsOffWork"
  | "IsPriorMedications"
  | "IsRepresented"
  | "IsInSuit";
export interface ClaimInput {
  DateOfAccident?: Date | null;
  Deadline?: Date | null;
  Insured: string;
  ClaimNumber: string;
  ClaimType?: {
    name: string;
    id: number;
  };
  Instructions?: string;
  Options: ClaimOption[];
}

export interface ClaimInfo {
  DateOfAccident?: string;
  Deadline?: string;
  Insured: string;
  ClaimNumber: string;
  ClaimType: number;
  Instructions?: string;
  IsPriorMedications: boolean;
  IsOffWork: boolean;
  IsRepresented: boolean;
  IsInSuit: boolean;
}

export interface MedicalInfo {
  Targets: Target[];
  Facilities: number[];
}

export enum CartItemRequirementType {
  Additional,
  Medical,
}

export interface FacilityType {
  Id: string;
  Description: string;
  SortOrder: number;
}

export interface MaritalStatus {
  Label: string;
  Value: number;
  SortOrder: number;
}

export interface MedicalCheckFormValues {
  Targets: Address[];
  FacilityTypes: FacilityTypeItem[];
  MedicalBundle: string | number;
  RangeId: number;
}

export interface ISelectItem {
  value: string | number;
  name?: string;
}

export interface FacilityTypeItem {
  id: number;
  name: string;
  count: number;
}

export interface MedicalBundle {
  id: number;
  name: string;
  description: string;
  facilityTypes: number[];
  rangeId: number;
  sortOrder: number;
}

export interface MedicalRange {
  id: number;
  name: string;
  quantity: number;
}

export type CheckoutValues = {
  Claim: ClaimInput;
  Subject: SubjectInput;
  Medical: MedicalCheckFormValues;
};

export type CheckoutReviewValues = Omit<OrderRequest, "clientId">;
