import { inIframe } from "@Utils/helpers";
import axios, { AxiosError } from "axios";
import { INVALID_REFRESH_TOKEN } from "./auth/constants";
import Debug from "@Utils/debug";
import { SESSION_HEADER_KEY, STORAGE_KEY_MAP } from "@Utils/constants";

const debug = Debug("request");

const client = axios.create({
  withCredentials: true,
});

client.interceptors.response.use(
  r => r,
  (err: AxiosError<any>) => {
    try {
      debug.error("Axios Response Error", err.toJSON(), err.response);
      if (!inIframe() && typeof window !== "undefined") {
        if (
          err.response &&
          err.response.data &&
          err.response.data.code === INVALID_REFRESH_TOKEN
        ) {
          let payload;

          payload = {
            url: err.config.url,
            params: err.config.params,
          };

          if (!(window as any).SILENT_RENEWAL_ACTIVE) {
            debug.error("Axios Response Error - start silent renewal");
            window.postMessage(
              {
                source: "start_silent_renew",
                payload,
              },
              "*",
            );
          } else {
            window.postMessage(
              {
                source: "in_silent_renew",
                payload,
              },
              "*",
            );
          }
        }
      }
    } catch {}

    return Promise.reject(err);
  },
);

client.interceptors.request.use(
  config => {
    config.headers[SESSION_HEADER_KEY] = sessionStorage.getItem(STORAGE_KEY_MAP.session);
    return config;
  },
  error => Promise.reject(error)
)

export default client;
