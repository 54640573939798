export const inBrowser = () =>
  typeof window !== "undefined" || Boolean((process as any).browser);
export const isBrowser = inBrowser;
export const inServer = () => !inBrowser();

export const parseUrl = (url?: string) => {
  const defaultHost = "http://localhost:3000";
  const defaultPath = "/api/me"
  if (!url) url = `${defaultHost}${defaultPath}`;
  
  try {
    const urlObj = new URL(url);
    
    return {
      base: `${urlObj.protocol}//${urlObj.hostname}`,
      path: urlObj.pathname !== "/" ? urlObj.pathname : defaultPath
    }
  } catch {
    return {
      base: defaultHost,
      path: defaultPath
    }
  }
}