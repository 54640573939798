import { createTheme } from "@material-ui/core/styles";
import * as Fonts from "./fonts";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000052",
      light: "#24507F",
    },
    secondary: {
      main: "#A92523",
    },
    info: {
      main: "#FFAD0A",
    },
    warning: {
      main: "#d29e89",
    },
    background: {
      default: "#3e3e3e",
    },
    success: {
      main: "#8CAD1F",
    },
  },
  typography: {
    fontWeightBold: 700,
    h1: {
      fontFamily: "Assistant, sans-serif",
    },
    h2: {
      fontFamily: "Assistant, sans-serif",
    },
    h3: {
      fontFamily: "Assistant, sans-serif",
    },
    h4: {
      fontFamily: "Assistant, sans-serif",
    },
    h5: {
      fontFamily: "Assistant, sans-serif",
    },
    h6: {
      fontFamily: "Assistant, sans-serif",
    },
    fontFamily: "Lato, sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          Fonts.latoRegular,
          Fonts.latoLight,
          Fonts.latoBold,
          Fonts.assistantRegular,
          Fonts.assistantLight,
        ],
      },
    },
  },
});

export default theme;
