import React from "react";
import { IClaims, ISessionClient } from "@Lib/auth/session/session";
import useRequest, { makeResponse, MutateFn } from "@Hooks/useRequest";

import request from "@Lib/request";
import { Client } from "@Types";
import { inBrowser } from "@Utils/nextUtils";
import { useUser } from "./AuthProvider";
// import Debug from "@Utils/debug";
import { STORAGE_KEY_MAP } from "@Utils/constants";
import useStorage from "@Hooks/useStorage";
import { AxiosError } from "axios";

// const debug = Debug("ClientProvider");

export const STORAGE_KEY = STORAGE_KEY_MAP.client;

const setClient = (client: ISessionClient) => {
  if (inBrowser()) sessionStorage.setItem(STORAGE_KEY, JSON.stringify(client));
  __CLIENT.client = client;
};

// @ts-ignore
const getClient = () => {
  if (!inBrowser()) return null;

  const client = sessionStorage.getItem(STORAGE_KEY);
  return client ? JSON.parse(client) : null;
};

const __CLIENT: {
  client: Client | null;
  basePath: string;
} = {
  basePath: "/api/clients/me",
  client: null,
};

export const fetchClient = async () => {
  const response = await request({
    url: __CLIENT.basePath,
  });

  return response;
};

export interface IClientContext {
  client: Client | null;
  loading: boolean;
  update: (client: Client) => Promise<any>;
  mutate: MutateFn<Client | null>;
  error?: AxiosError<unknown>;
}

export type ClientProviderState = Omit<IClientContext, "update">;

export const ClientContext = React.createContext<IClientContext>({
  client: null,
  loading: false,
  update: () => {
    throw new Error("Context not initialized.");
  },
  mutate: () => {
    throw new Error("Context not initialized.");
  },
});

export interface ClientProviderProps {
  value?: {
    client: Client | null;
    loading: boolean;
  };
  children: any;
}

const ClientProvider = ({ children }: ClientProviderProps) => {
  // const [client, setClient] = React.useState(value?.client || __CLIENT.client);
  const { user, silent } = useUser();
  const storage = useStorage("client");

  const { client, loading, update, mutate, error } = useFetchClient(
    user,
  );

  React.useEffect(() => {
    if (client && error && !user && !silent) {
      __CLIENT.client = null;
      storage.clear();
      mutate(makeResponse(null), { revalidate: false });
    }
  }, [error, user, client, silent, storage, mutate]);

  React.useEffect(() => {
    let storedClient = storage.get();
    if (client && !storedClient) {
      storage.set(client);
    }

    if (!client && storedClient) {
      storage.clear();
    }

    // __CLIENT.client = client;
  }, [client, loading, storage]);

  return (
    <ClientContext.Provider value={{ client, loading, update, mutate, error }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => React.useContext(ClientContext);

export const useFetchClient = (
  user?: IClaims | null,
) => {
  const { data, mutate, error, isValidating } = useRequest<Client | null>(
    user
      ? {
          url: __CLIENT.basePath,
        }
      : null,
    {
      // If client was passed in use that - otherwise use global
      // fallbackData: client || null,
      focusThrottleInterval: 10 * 60 * 1000,
      // fetcher: fetchClient,
      // No needless revalidations
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      errorRetryCount: 1,
      // retry conditionally
      onErrorRetry: (error, _, __, revalidate, opts) => {
        if (error.code && error.code === "400") return;
        if (opts.retryCount && opts.retryCount > 0) return;
        const timeout = 5000;
        setTimeout(revalidate, timeout, opts);
      },
    },
  );

  const update = async (client: Client) => {
    const response = await request({
      url: __CLIENT.basePath,
      method: "POST",
      data: client,
    });

    if (response && response.data) {
      mutate(response.data);
      setClient(response.data);
      return response.data;
    }
  };

  return {
    client: data || null,
    loading: !data && isValidating,
    update,
    mutate,
    error,
  };
};

export default ClientProvider;
