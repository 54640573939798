import axios, { AxiosInstance } from "axios";
import https from "https";

const create = (): AxiosInstance => {
  return axios.create({
    httpsAgent: new https.Agent({
       rejectUnauthorized: false,
       keepAlive: true,
    })
  });
}

const inst = create();

export default inst;