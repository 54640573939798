import { Address } from "@Types";

export const onlyDigits = (value?: string, _name?: string) =>
  value ? value.replace(/[^\d]/g, "") : "";

export const onlyAlpha = (value?: string, _name?: string) =>
  value ? value.replace(/[^a-zA-Z]/g, "") : "";

export class StringFormatter {
  input?: string;

  constructor(input?: any) {
    this.input = input ? input.toString() : "";
  }

  public asMoney(): string {
    let validPattern = /^\$?\d+(?:\.\d*)?$/;
    if (!this.input) return "$0.00";
    //throw new Error("Cannot format as money: invalid input string");

    if (!validPattern.test(this.input)) return "$0.00";
    let result = parseFloat(this.input.replace(/[^\d.]/g, ""));
    if (result === 0) return "$0.00";

    // Trim to two places
    result = Math.round(result * 100) / 100;
    // Split on decimal separator
    let [whole, decimal] = result.toString().split(".");

    let decimals = decimal ? decimal.toString().split("") : [0, 0];
    decimal = [decimals[0], decimals[1] || 0].join("");

    return `$${[whole, decimal].join(".")}`;
  }
}

export function asMoney(input?: string | number) {
  if (typeof input === "number") input = input.toString(10);
  else if (typeof input === "undefined") input = "0";

  let validPattern = /^\$?\d+(?:\.\d*)?$/;

  if (!validPattern.test(input)) return "$0.00";
  let result = parseFloat(input.replace(/[^\d.]/g, ""));
  if (result === 0) return "$0.00";

  // Trim to two places
  result = Math.round(result * 100) / 100;
  // Split on decimal separator
  let [whole, decimal] = result.toString().split(".");

  let decimals = decimal ? decimal.toString().split("") : [0, 0];
  decimal = [decimals[0], decimals[1] || 0].join("");

  return `$${[whole, decimal].join(".")}`;
}

export function formatValue(input?: string | number) {
  return new StringFormatter(input);
}

export const toPascalCase = (input: any) => {
  const stringValue = String(input);
  return stringValue.replace(
    /^(.)(.*)/,
    (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
  );
};

export const ensureSlash = (text: string) =>
  text.charAt(text.length - 1) === "/" ? text : text + "/";

export const formatAddress = ({ Street, City, State, Zip }: Address) =>
  [Street, City, State, Zip].filter(v => typeof v !== "undefined").join(", ");

export const convertToString = (
  value?:
    | string
    | number
    | boolean
    | Date
    | null
    | (string | number | boolean | Date)[],
  format?: (text: string) => string,
): string => {
  let out = "";
  switch (typeof value) {
    case "number": {
      out = format ? format(value.toString(10)) : value.toString(10);
      break;
    }
    case "boolean": {
      out = toPascalCase(value.toString());
      break;
    }
    case "object": {
      // check if this is a date
      if (value instanceof Date && !isNaN(value.valueOf())) {
        out = value.toLocaleDateString();
      } else if (Array.isArray(value)) {
        out = value.map(v => convertToString(v, format)).join(",");
      }
      break;
    }
    case "string": {
      out = value.length > 0 ? (format ? format(value) : value) : "N/A";
      break;
    }
    default: {
      out = "N/A";
      break;
    }
  }

  return out;
};
