import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import axios from "../request";
import { MutatorCallback } from "swr/dist/types";

export type GetRequest =
  | AxiosRequestConfig
  | (() => AxiosRequestConfig | null)
  | null;

interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    "isValidating" | "error" | "mutate"
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    "fallbackData"
  > {
  fallbackData?: Data;
}

export interface MutateCallback<Data = unknown> {
  (data: Data): Promise<Data>;
}

export interface MutateFn<Data> {
  (
    data?:
      | AxiosResponse<Data>
      | Promise<AxiosResponse<Data>>
      | MutatorCallback<AxiosResponse<Data>>,
    shouldRevalidate?: boolean | undefined,
  ): Promise<AxiosResponse<Data> | undefined>;
}

export const makeResponse = <T>(
  data: T,
  config?: AxiosRequestConfig,
  responseText?: string,
): AxiosResponse<T> => {
  return {
    status: 200,
    statusText: responseText || "MakeResponse",
    data: data,
    headers: {},
    config: config || {},
  };
};

function isFunction(obj: any): obj is Function {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}

export default function useRequest<Data = unknown, Error = unknown>(
  request: GetRequest,
  { fallbackData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
  let req = request && isFunction(request) ? request() : request;

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    req && JSON.stringify(req),
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => (config.fetcher ? config.fetcher(req!) : axios(req!)),
    {
      ...config,
      fallbackData: fallbackData && {
        status: 200,
        statusText: "InitialData",
        config: req!,
        headers: {},
        data: fallbackData,
      },
    },
  );

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate,
  };
}
