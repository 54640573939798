import { Schema, ValidationError } from "yup";
import { ValidationErrors } from "final-form";
import { onlyDigits } from "./stringUtils";
import { UseFieldConfig, FieldMetaState } from "react-final-form";
import { Office, Product, CartItemRequirementType } from "../interfaces";
import Debug from "@Utils/debug";

const debug = Debug("utils:formUtils");

export type MuiVariant = "standard" | "outlined" | "filled";

export const ensureMuiVariant = (variant?: MuiVariant): MuiVariant => {
  let tsVariant: MuiVariant;
  switch (variant) {
    case "outlined":
      tsVariant = variant as "outlined";
      break;
    case "filled":
      tsVariant = variant as "filled";
      break;
    case "standard":
      tsVariant = variant as "standard";
      break;
    default:
      tsVariant = "standard" as "standard";
      break;
  }

  return tsVariant;
};

export const extractFieldConfig = <T = any>({
  afterSubmit,
  allowNull,
  beforeSubmit,
  defaultValue,
  validate,
  value,
  validateFields,
  initialValue,
  format,
  formatOnBlur,
  parse,
  multiple,
  isEqual,
  subscription,
  type,
  ...rest
}: FieldProps<T, any>): [
  UseFieldConfig<any>,
  Omit<T, keyof UseFieldConfig<any>>,
] => [
  {
    beforeSubmit,
    afterSubmit,
    allowNull,
    format,
    formatOnBlur,
    parse,
    value,
    validate,
    validateFields,
    initialValue,
    defaultValue,
    multiple,
    isEqual,
    type,
    subscription,
  },
  rest as Omit<T, keyof UseFieldConfig<any>>,
];

export type FieldProps<F, C> = UseFieldConfig<F> &
  (C extends React.ComponentType<infer CProps> ? CProps : C);

export const createValidator = <T>(schema: Schema<T>) => {
  return async (values: T) => {
    const errors: any = {};

    try {
      await schema.validate(values, {
        abortEarly: false,
        //      strict: true,
      });
    } catch (err) {
      debug.error(err);
      for (let validationError of (err as ValidationError).inner) {
        // need to check if error path is array
        const arrayPattern = /^(\w+)\[(\d+)](?:\.(\w+))?$/;
        // TODO: Maybe also check if object path

        let parts = validationError.path.match(arrayPattern);
        if (parts) {
          const [path, index, innerPath] = parts.slice(1);

          if (!errors.hasOwnProperty(path) || !Array.isArray(errors[path])) {
            errors[path] = [];
          }

          errors[path][index] = innerPath
            ? { ...errors[path][index], [innerPath]: validationError.message }
            : validationError.message;
        } else {
          errors[validationError.path] = validationError.message;
        }
      }
    }

    if (Object.keys(errors).length > 0) debug.debug("Form errors: %o", errors);

    return errors as ValidationErrors;
  };
};

export const isValidPhone = (
  key: string,
): [string, (args: any) => any, (args: any) => any] => {
  return [
    key,
    ({ path }) => `${path} should be a 10-digit phone number`,
    value =>
      onlyDigits(value).length === 10 ||
      value === "" ||
      typeof value === "undefined",
  ];
};

export const normalizeSSN = (value: string) => {
  if (!value) return value;
  const numbers = onlyDigits(value);

  if (numbers.length <= 3) return numbers;
  else if (numbers.length <= 5)
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}`;

  return `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}-${numbers.slice(5, 9)}`;
};

export const normalizePhone = (value: string) => {
  if (!value) return value;
  const onlyNums = onlyDigits(value);
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7)
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const maxLength =
  (count: number): ParserFn =>
  (value?: string, _name?: string) =>
    value ? value.slice(0, count) : "";

export type ParserFn = (value: string, name: string) => string;

export const parse =
  (parsers: ParserFn | ParserFn[]) => (value: any, name: string) => {
    let out = value || "";
    if (typeof parsers === "function") {
      return parsers(out, name);
    } else if (Array.isArray(parsers)) {
      for (var parser of parsers) {
        out = parser(out, name);
      }
    }

    return out;
  };

export const parsePostal = (value: string, _name?: string) => {
  const numbers = onlyDigits(value);

  return numbers.slice(0, 5);
};

export const ensureFieldName = (props: any): string => {
  const baseName = props.name || props.id;

  if (!baseName)
    throw new Error("[ensureFieldName] You must enter a name or id");

  return baseName;
};

export const getShowError = (meta: FieldMetaState<any>) =>
  ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
  meta.touched;

export const getOfficeFullAddress = (office: Office) =>
  `${office.Street}, ${office.City}, ${office.State}, ${office.Zip}`;

export const getItemRequirements = (
  item: Product,
): CartItemRequirementType[] => {
  let requirements = [];
  if (["1008", "1001", "1005", "1006"].includes(item.Id))
    requirements.push(CartItemRequirementType.Additional);
  if (item.IsMedical) requirements.push(CartItemRequirementType.Medical);

  return requirements;
};

export const getRequiresAdditional = (items: Product[]) =>
  items.reduce((res, current) => {
    let req = getItemRequirements(current);
    return (res = res || req.includes(CartItemRequirementType.Additional));
  }, false);

export const getRequiresMedical = (items: Product[]) =>
  items.reduce((res, current) => {
    let req = getItemRequirements(current);
    return (res = res || req.includes(CartItemRequirementType.Medical));
  }, false);
