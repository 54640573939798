import React from "react";
import Head from "next/head";
import { CssBaseline } from "@material-ui/core";
import {
  StylesProvider,
  MuiThemeProvider as ThemeProvider,
} from "@material-ui/core/styles";
import { SWRConfig } from "swr";
import theme from "../theme";
import CartProvider from "../src/context/CartProvider";
import { AppProps } from "../src/interfaces";
import { ChatwootProvider, useFetchChatwootConfig } from "@Lib/chatwoot";
import Axios from "axios";
import AuthProvider from "src/context/AuthProvider";
import ClientProvider from "src/context/ClientProvider";
import Debug from "@Utils/debug";
import useStorage from "@Hooks/useStorage";
import * as uuid from "uuid";

const debug = Debug("pages:_app");
Axios.defaults.withCredentials = true;

function App(props: AppProps<any>) {
  const { Component, pageProps } = props;

  const storage = useStorage();

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");

    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    let sessionId = storage.get("session");
    if (sessionId == null) {
      sessionId = uuid.v4();
      debug.debug("Storing session id as: %s", sessionId);
      storage.set(sessionId, "session");
      return;
    }

    debug.debug("Found session id as: %s", sessionId);
  }, [storage]);

  React.useEffect(() => {
    const cb = (e: MessageEvent) =>
      e.data && e.data.source && (e.data.source as string).startsWith("react")
        ? null
        : debug.debug("Message posted to window", e);
    window.addEventListener("message", cb);

    return () => window.removeEventListener("message", cb);
  }, []);

  React.useEffect(() => {
    debug.debug("_app: pageProps %o", pageProps);
  }, [pageProps]);

  const config = useFetchChatwootConfig();

  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Head>
            <title>Trace America</title>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
          </Head>

          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              refreshWhenOffline: false,
              refreshWhenHidden: false,
              isPaused: () => typeof window != "undefined" ? !!(window as any).SILENT_RENEWAL_ACTIVE : false,
            }}
          >
            <AuthProvider>
              <ClientProvider>
                <ChatwootProvider
                  value={{
                    config,
                    settings: {
                      launcherTitle: "Chat with us!",
                      type: "expanded_bubble",
                      showPopoutButton: true,
                    },
                  }}
                >
                  <CartProvider>
                    <Component {...pageProps} />
                  </CartProvider>
                </ChatwootProvider>
              </ClientProvider>
            </AuthProvider>
          </SWRConfig>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

export default App;
