import { AtRule } from "csstype";
import LatoRegularTTF from 'public/fonts/Lato-Regular.ttf';
import LatoBoldTTF from 'public/fonts/Lato-Bold.ttf';
import LatoLightTTF from 'public/fonts/Lato-Light.ttf';
import AssistantRegularTTF from 'public/fonts/Assistant-Regular.ttf';
import AssistantLightTTF from 'public/fonts/Assistant-Light.ttf';

export enum FontWeight {
  "Light" = 300,
  "Normal" = 400,
  "Bold" = 700,
}

// Lato
export const latoRegular: AtRule.FontFace = {
  fontFamily: "Lato",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontVariant: "sans-serif",
  fontWeight: FontWeight.Normal,
  src: `
    local('Lato'),
    local('Lato-Regular'),
    url(${LatoRegularTTF}) format('ttf')
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

export const latoBold: AtRule.FontFace = {
  ...latoRegular,
  fontWeight: FontWeight.Bold,
  src: `
    local('Lato'),
    local('Lato-Bold'),
    url(${LatoBoldTTF}) format('ttf')
  `
}

export const latoLight: AtRule.FontFace = {
  ...latoRegular,
  fontWeight: FontWeight.Light,
  src: `
    local('Lato'),
    local('Lato-Light'),
    url(${LatoLightTTF}) format('ttf')
  `
}

// Assistant
export const assistantRegular: AtRule.FontFace = {
  fontFamily: "Assistant",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: FontWeight.Normal,
  src: `
    local('Assistant'),
    local('Assistant-Regular'),
    url(${AssistantRegularTTF}) format('ttf')
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

export const assistantLight: AtRule.FontFace = {
  ...assistantRegular,
  fontWeight: FontWeight.Bold,
  src: `
    local('Assistant'),
    local('Assistant-Light'),
    url(${AssistantLightTTF}) format('ttf')
  `
}